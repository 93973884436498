import lightGallery from 'lightgallery';
class Store {
    constructor() {
        this.namespace = document.getElementById('page-store');   
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;
        console.log('Store page initialized')

        this.setupWindowEvents();
        this.setupListeners(); 
        this.setupGallery();
    }

    setupGallery() {
        lightGallery(document.querySelector('.gallery-selector'), {
            speed: 500,
            download: false,
            selector: 'a'
        });
    }

    setupListeners(){
        const variantButtons = document.querySelectorAll('.btn-variant');
        variantButtons.forEach(button => {
            button.addEventListener('click', this.handleVariantClick.bind(this));
        });
    }

    handleVariantClick(e) {
        const button = e.currentTarget;
    
        document.querySelectorAll('.btn-variant').forEach(button => {
            button.classList.remove('active');
        });

        button.classList.add('active');
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
 
    }


    destroy() {

    }
}
export default Store;
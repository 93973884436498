
class Artists {
    constructor() {
        this.namespace = document.getElementById('page-artists');   
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;
        console.log('Artists page initialized')

        this.setupWindowEvents();
        this.setupListeners(); 
    }

    setupListeners(){
        
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
 
    }


    destroy() {

    }
}
export default Artists;
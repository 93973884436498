
class Releases {
    constructor() {
        this.namespace = document.getElementById('page-releases');   
        this.tracks = [];
        this.audioContainer = document.querySelector('.audio-player');
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;
        
        this.setupPlayers();
        this.setupWindowEvents();
        this.setupListeners(); 
    }

    setupPlayers() {
        this.players = document.querySelectorAll('iframe');

        this.players.forEach(player => {
            this.tracks.push(SC.Widget(player));
        });
    }

    playTrack(i) {
        this.players.forEach(player => {
            player.style.display = 'none';
        });

        this.players[i].style.display = 'block';
        this.tracks[i].play();
    }

    stopTrack(i) {
        this.tracks[i].pause();
    }

    setupListeners(){
        const playButtons = document.querySelectorAll('.btn-play');

        playButtons.forEach((button, i) => {
            button.addEventListener('click', () => {
                this.playTrack(i);
            });
        });
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
 
    }


    destroy() {

    }
}
export default Releases;
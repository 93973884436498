import Rellax from 'rellax';

class Home {
    constructor() {
        this.namespace = document.getElementById('page-home');
        this.siteWidth = 1100;        
        
        
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;

        this.setupScroll();
        this.setupWindowEvents();
        this.setupListeners(); 
    }

    setupScroll() {
        this.rellax = new Rellax('.rellax');
    }

    setupListeners(){
        
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
 
    }


    destroy() {

    }
}
export default Home;

class Contact {
    constructor() {
        this.namespace = document.getElementById('page-contact');   
        this.initPage();
    }

    initPage() {
        if (!this.namespace) return;
        this.contactForm = document.getElementById('contactForm');
        this.contactForm.addEventListener('submit', this.submitForm.bind(this));
        this.thankYou = document.getElementById('contact-thankyou');
    }

    submitForm(e) {
        e.preventDefault();

        const form = new FormData(this.contactForm);
        const name = form.get("name");
        const enquiryType = form.get("enquiryType");
        const message = form.get("message");
        const email = form.get("email");

        if (!name || !enquiryType || !message || !email) {
            return;
        }
        
        const send = async () => {    
            await fetch(
                `/.netlify/functions/sendContactForm`,
                {
                  method: "POST",
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    name,
                    email,
                    from: "website@elektrons.io",
                    to: "luke@freemandigital.com",
                    subject: "Contact Form Submission",
                    enquiryType, 
                    message,
                  }),
                }
              );
        };

        send();
        this.thankYou.style.display = "block";
        return false;
    }

}
export default Contact;
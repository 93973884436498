import barba from '@barba/core';
import lottie from 'lottie-web';
import Lenis from '@studio-freight/lenis'
import Home from './Home';
import Releases from './Releases';
import Artists from './Artists';
import Store from './Store';
import Contact from './Contact';
import imagesLoaded from 'imagesloaded';
import Subscribe from './Subscribe';

class Site {
    constructor() {
        this.setupRouting.bind(this);
        this.setupRouting();      
        this.pages = [];
        this.footer = document.getElementById('footer');
        this.splash = document.getElementById('splash');
        this.logoNav = document.querySelector('.logo-nav');
        this.logoTitle = document.querySelector('.logo-nav-title');
        this.controls = document.querySelector('.controls');
        this.hannesPic = document.querySelector('.hannes');
        this.mainVideo = document.getElementById('mainVideo');


        console.log(this.contactForm);

        
        this.initPage();
        this.setupLottie();
    }

    setupSmoothScroll() {
      this.lenis = new Lenis({
        lerp: 0.2
      })

      this.lenis.on('scroll', (e) => {
          if (e.targetScroll < 100) {
              this.logoTitle.classList.add('show');
          } else {
              this.logoTitle.classList.remove('show');
          }

          if (e.targetScroll > 1000) {
            if (this.hannesPic) this.hannesPic.classList.add('active');
          } else {
            if (this.hannesPic) this.hannesPic.classList.remove('active');
          }
      });

      const raf = (time) => {
          this.lenis.raf(time)
          requestAnimationFrame(raf)
      }

      requestAnimationFrame(raf)
    }

    setupRouting() {
      const _self = this;

      barba.init({
          schema: {
              prefix: 'data-site',
              wrapper: 'app'
          },
          views: [{
            namespace: 'site',
            beforeLeave() {
              _self.destroyPage();
            },
            afterEnter() {
              window.scrollTo({
                  top: 0
              });
            }
          }]
      });

      barba.hooks.before(() => {
        this.mainVideo.classList.add('hidden');
        document.querySelector('.site-wrapper').classList.add('is-leaving');
        document.querySelector('.preloader').classList.add('show');
      });

      barba.hooks.after(() => {
        this.initPage();
        
        setTimeout(() => {
          document.querySelector('.site-wrapper').classList.remove('is-leaving');
          document.querySelector('.preloader').classList.remove('show');
          this.mainVideo.classList.remove('hidden');
        }, 300);
      });
    }

    setupLottie() {

        const aniElement = document.getElementById('intro-logo');

        var animation = lottie.loadAnimation({
            container: aniElement,
            renderer: 'svg',
            loop: false,
            autoplay: true,
            path: '/static/ani/logo_intro.json'
        });

        animation.setSpeed(2.5);

        animation.addEventListener('complete', () => {
            this.splash.classList.remove('is-active');

            setTimeout(() => {
                this.splash.classList.add('locked');
            }, 200);
        });

        lottie.loadAnimation({
            container: this.logoNav,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/static/ani/logo_nav.json'
        });

    }

    loadPages(){
      this.pages.push(new Home());
      this.pages.push(new Releases());
      this.pages.push(new Artists());
      this.pages.push(new Store());
      this.pages.push(new Contact());
      this.pages.push(new Subscribe());
    }

    destroyPage() {
      this.pages.forEach(page => {
        page.destroy();
      });
    }

    initPage() {
      this.loadPages();
      this.setupListeners();   

      imagesLoaded( document.querySelector('.site-container'), ( instance ) => {
        this.setupSmoothScroll();
      });
    }

    handleNavOpen() {
      this.controls.classList.add('active');
    }

    handleNavClose() {
        this.controls.classList.remove('active');
    }

    setupListeners() {
      this.controls.addEventListener('mouseover', this.handleNavOpen.bind(this));
      this.controls.addEventListener('mouseout', this.handleNavClose.bind(this));

      const link_releases = document.querySelector('.link-releases');
      const link_artists = document.querySelector('.link-artists');
      const link_store = document.querySelector('.link-store');

      link_releases.addEventListener('mouseover', () => {
        document.querySelector('.dota').classList.add('active');
      });

      link_artists.addEventListener('mouseover', () => {
        document.querySelector('.dotb').classList.add('active');
      });

      link_store.addEventListener('mouseover', () => {
        document.querySelector('.dotc').classList.add('active');
      });

      link_releases.addEventListener('mouseout', () => {
        document.querySelector('.dota').classList.remove('active');
      });

      link_artists.addEventListener('mouseout', () => {
        document.querySelector('.dotb').classList.remove('active');
      });

      link_store.addEventListener('mouseout', () => {
        document.querySelector('.dotc').classList.remove('active');
      });


      link_releases.addEventListener('touchstart', () => {
        document.querySelector('.dota').classList.add('active');
      });

      link_artists.addEventListener('touchstart', () => {
        document.querySelector('.dotb').classList.add('active');
      });

      link_store.addEventListener('touchstart', () => {
        document.querySelector('.dotc').classList.add('active');
      });

      link_releases.addEventListener('touchend', () => {
        document.querySelector('.dota').classList.remove('active');
      });

      link_artists.addEventListener('touchend', () => {
        document.querySelector('.dotb').classList.remove('active');
      });

      link_store.addEventListener('touchend', () => {
        document.querySelector('.dotc').classList.remove('active');
      });
    }
}

export default Site;

class Subscribe {
    constructor() {
        this.initPage();
    }

    initPage() {
        this.subscribeForm = document.getElementById('subscribe-form');
        this.subscribeForm.addEventListener('submit', this.submitForm.bind(this));
        this.subEmail = document.getElementById('sub-email');
        this.thankYou = document.getElementById('sub-thanks');
    }

    submitForm(e) {
        e.preventDefault();

        if (!this.subEmail.value) {
            return false;
        }

        const send = async () => {    

            this.thankYou.style.display = 'block';
            
            const { status } = await fetch("https://38895b1d.sibforms.com/serve/MUIFAGjj8e4WOAjY-LFP0bORLmy0TvqTNFdPBZOX-5njagdoHNlDtAHmzLxdsTBvsnd5R-sgS5342KrL05j0VvHnJ4LlqYL3S5f9ZRsOSOIbaZZEUDl8LuPBhlmU0ZWlkgIRj1CBehXzUJqJH1TkkXTXC1EvNkCvmXEo4EJzxrwQII86d8f1ruWxG03cBlauXGHhpQSuqbiXZjDD", {
                method: "POST",
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded'
                  },    
                  body: new URLSearchParams({
                    EMAIL: this.subEmail.value,
                    email_address_check: '',
                    locale: 'en',
                    html_type: 'simple',
                  })
               
            });

            this.subEmail.value = '';

            if (status !== 200) {
                throw new Error(`Error ${status} occured while sending message.`);
            }
        };

        send();
        return false;
    }

}
export default Subscribe;